import {
  Flex,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Box,
} from '@chakra-ui/react';
import router from 'next/router';
import { MdGames, MdOutlineLiveTv, MdQuiz } from 'react-icons/md';
import { IoGameControllerSharp } from 'react-icons/io5';
import { BsFiletypeKey } from 'react-icons/bs';
import { FaAndroid, FaApple } from 'react-icons/fa';

export default function GamesMenu() {
  const gamesInfos = [
    {
      title: 'Quiz',
      icon: <MdQuiz />,
      url: '/game?id_game=37&id_stage=16&type=6',
    },
    {
      title: 'Roleta',
      icon: <MdGames />,
      url: '/game?id_game=40&id_stage=16&type=10',
    },
    {
      title: 'Cinco Letras',
      icon: <BsFiletypeKey />,
      url: '/game?id_game=41&id_stage=16&type=3',
    },
    {
      title: 'Código Secreto',
      icon: <MdOutlineLiveTv />,
      url: '/game?id_game=39&id_stage=16&type=4',
    },
    {
      title: 'Raspadinha',
      icon: <IoGameControllerSharp />,
      url: '/game?id_game=38&id_stage=16&type=7',
    },
    {
      title: 'SBT Games IOS',
      icon: <FaApple />,
      url: 'https://apps.apple.com/pa/developer/tvsbt-canal-4-de-sao-paulo-s-a/id654984185',
      openExternal: true,
    },
    {
      title: 'SBT Games Android',
      icon: <FaAndroid />,
      url: 'https://play.google.com/store/apps/dev?id=6463902530060872680&hl=pt_BR&gl=US',
      openExternal: true,
    },
  ];

  return (
    <Flex alignItems="center">
      <Menu>
        <MenuButton
          py={2}
          transition="all 0.3s"
          _focus={{ boxShadow: 'none' }}
          display={{ base: 'none', md: 'flex' }}
        >
          <HStack spacing="4">
            <VStack
              align="flex-start"
              spacing="1px"
              ml="2"
              fontFamily={'mainfont'}
              color="#1D1D1D"
            >
              <Text fontFamily={'mainfont'} color="#1D1D1D">
                Games
              </Text>
            </VStack>
          </HStack>
        </MenuButton>
        <MenuList color="#1D1D1D" bg="#FDDC15">
          {gamesInfos.map((game, index) => (
            <Box key={`link_menu_${index}`}>
              {index != 0 && <MenuDivider />}
              <a
                target={game.openExternal == true ? '_blank' : ''}
                href={game.url}
                rel="noopener noreferrer"
              >
                <MenuItem
                  color="#1D1D1D"
                  bg="#eecd0f"
                  icon={game.icon}
                  onClick={() => {
                    if (!game.openExternal) {
                      router.push(game.url);
                    }
                  }}
                >
                  {game.title}
                </MenuItem>
              </a>
            </Box>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
}
