import { useEffect } from 'react';
import { useRouter } from 'next/router';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  Divider,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';

import NavLink from '@components/NavLink';
import { VscBook } from 'react-icons/vsc';
import { BsQuestionCircle } from 'react-icons/bs';
import { IoDocumentOutline } from 'react-icons/io5';

import { MdGames, MdOutlineLiveTv, MdQuiz } from 'react-icons/md';
import { IoGameControllerSharp } from 'react-icons/io5';
import { BsFiletypeKey } from 'react-icons/bs';
import { FaAndroid, FaApple, FaMoneyCheck } from 'react-icons/fa';

const LinkItems = [
  { label: 'Regulamento', icon: VscBook, href: '/regulations' },
  { label: 'Dúvidas?', icon: BsQuestionCircle, href: '/faq' },
  { label: 'Política de privacidade', icon: IoDocumentOutline, href: '/terms' },
];

export default function Sidebar({
  onClose,
  ...rest
}: {
  onClose: any;
  rest?: any;
}) {
  const router = useRouter();

  function findPosition(obj: any) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
    }
    return currenttop;
  }
  function scrolldiv() {
    window.scrollTo({
      left: 0,
      top: findPosition(document.getElementById('participate_plans')) - 80,
      behavior: 'smooth',
    });
  }

  const gamesInfos = [
    {
      label: 'Quiz',
      icon: MdQuiz,
      href: '/game?id_game=37&id_stage=16&type=6',
    },
    {
      label: 'Roleta',
      icon: MdGames,
      href: '/game?id_game=40&id_stage=16&type=10',
    },
    {
      label: 'Cinco Letras',
      icon: BsFiletypeKey,
      href: '/game?id_game=41&id_stage=16&type=3',
    },
    {
      label: 'Código Secreto',
      icon: MdOutlineLiveTv,
      href: '/game?id_game=39&id_stage=16&type=4',
    },
    {
      label: 'Raspadinha',
      icon: IoGameControllerSharp,
      href: '/game?id_game=38&id_stage=16&type=7',
    },
  ];

  const externalMenuLinks = [
    {
      label: 'SBT Games IOS',
      icon: FaApple,
      href: 'https://apps.apple.com/pa/developer/tvsbt-canal-4-de-sao-paulo-s-a/id654984185',
      openExternal: true,
    },
    {
      label: 'SBT Games Android',
      icon: FaAndroid,
      href: 'https://play.google.com/store/apps/dev?id=6463902530060872680&hl=pt_BR&gl=US',
      openExternal: true,
    },
  ];

  useEffect(() => {
    router.events.on('routeChangeComplete', onClose);
    return () => {
      router.events.off('routeChangeComplete', onClose);
    };
  }, [router.events, onClose]);

  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      w={{ base: 'full' }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text color="black" fontSize="2xl">
          {process.env.NEXT_PUBLIC_APP_NAME}
        </Text>
        <CloseButton
          color="black"
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link, i) => (
        <NavLink key={i} link={link} />
      ))}
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'orange.500',
        }}
        onClick={() => {
          onClose();
          scrolldiv();
        }}
      >
        <Icon mr="4" fontSize="16" color="black" as={FaMoneyCheck} />
        <Text color="black" fontSize="1.2rem">
          Assine Agora
        </Text>
      </Flex>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem border="none">
          <AccordionButton>
            <AccordionIcon />
            <Box color={'#363636'} ml="20px" fontSize={'lg'}>
              Games
            </Box>
          </AccordionButton>
          <AccordionPanel>
            {gamesInfos.map((link, i) => (
              <NavLink key={i} link={link} />
            ))}
            <Divider orientation="horizontal" />
            {externalMenuLinks.map((link, i) => (
              <NavLink key={i} link={link} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
