// import NextLink from 'next/link';
import { Flex, Icon, Text } from '@chakra-ui/react';

export default function NavLink({ link, ...rest }: { link: any; rest?: any }) {
  const { label, icon, href, openExternal } = link;

  return (
    <a
      href={href}
      target={openExternal == true ? '_blank' : ''}
      rel="noopener noreferrer"
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'orange.500',
        }}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" color="black" as={icon} />}
        <Text color="black" fontSize="1.2rem">
          {label}
        </Text>
      </Flex>
    </a>
  );
}
