import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer({
  minH
}:{minH?:string}) {
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box  
      pt={[5, 6, 9, 12]}
      // bg={'lpFooterColor'}
      backgroundImage={"url('/imgs/sbt_games/background_footer.png')"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={"relative"}
      zIndex={"overlay"}
      bottom={0}
      minH={minH}
      w={"full"}
    >
      <Container zIndex={0} maxW={{ base: 'full', md: '5xl', xl: '1690px' }} >
        <VStack zIndex={0} spacing={5}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="24px"
            align="center"
          >
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('banner-footer-duvidas')}
            </Link>
            <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="white"
            />
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('banner-footer-politica')}
            </Link>
          </Stack>
          <Text variant="lpFooterText" >
          {t('banner-footer-description')}
          </Text>
          <Text color="white">Desenvolvido por Máquina de Promos</Text>
        </VStack>
      </Container>
    </Box>
  );
}
